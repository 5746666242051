import React, { useEffect, useState, useCallback, useMemo, useRef, useLayoutEffect } from "react";
import jaugeImg from "../assets/jauge.png";
import { makeStyles, Box } from "@material-ui/core";
import btnImg from "../assets/btn.svg";
import btnBackground from "../assets/btnBackground.svg";
import orange from "@material-ui/core/colors/orange";
import needle from "../assets/needle.svg";

const useStyles = (
  isButtonPressed: boolean,
  isButtonActive: boolean,
  angle: number,
  bigButtonHeight: number
) =>
  makeStyles((theme) => ({
    jauge: {
      position: "relative",
      maxHeight: "70%",
      width: "80vw",
      height: "100%",
      textAlign: "center",
      "@media (orientation: portrait)": {
        height: "100%",
        width: "100%",
      },
    },
    img: {
      width: "100%",
      height: "100%",
      maxWidth: '800px',
      objectFit: "contain",
    },
    bigButton: {
      borderRadius: "50%",
      width: `${bigButtonHeight}px`,
      left: "50%",
      top: "50%",
      transform: `translate(-50%, 10%)`,
      position: "absolute",
    },
    btnBackground: {
      opacity: isButtonActive ? "1" : "0",
      transform: isButtonActive ? "scale(1)" : "scale(0.5)",
      transition: "all ease 0.3s",
    },
    btn: {
      cursor: "pointer",
      position: "absolute",
      width: "85%",
      height: "85%",
      transform: isButtonActive
        ? "translate(-50%, -50%)"
        : "translate(-50%, -50%) scale(0.5)",
      opacity: isButtonActive ? "1" : "0",
      left: "50%",
      top: isButtonPressed ? "50%" : "47%",
      borderRadius: "50%",
      transition: "all ease 0.3s",
      boxShadow: isButtonPressed
        ? `0px 1px 0px 0px ${orange[900]}`
        : `0px ${bigButtonHeight / 25}px 0px 0px ${orange[900]}`,
    },
    needle: {
      zIndex: -1,
      position: "absolute",
      height: "120%",
      left: "50%",
      transformOrigin: "bottom center",
      transform: `translateX(-50%) rotate(${angle}deg)`,
      bottom: "50%",
      transition: "all cubic-bezier(0.5,0.5,0,1) 0.5s",
    },
  }));

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const Jauge: React.ComponentType<{
  level: number;
  onButtonClick: () => void;
  isButtonActive: boolean;
}> = ({ level, onButtonClick, isButtonActive }) => {
  const [isButtonPressed, setIsButtonPressed] = useState(false);
  const [isButtonClickable, setIsButtonClickable] = useState(true);
  const angle = useMemo(() => {
    switch (level) {
      case 0:
        return -85;
      case 1:
        return -55;
      case 2:
        return -25;
      case 3:
        return 15;
      case 4:
        return 45;
      case 5:
        return 85;
    }
    return 30;
  }, [level]);
  const imgJaugeRef = useRef<HTMLImageElement>(null);
  const [bigButtonHeight, setBigButtonHeight] = useState(0);

  function getButtonHeight(jauge: HTMLImageElement) {
    var ratio = jauge.naturalWidth / jauge.naturalHeight;
    var width = jauge.height * ratio;
    var height = jauge.height;
    if (width > jauge.width) {
      width = jauge.width;
      height = jauge.width / ratio;
    }
    let buttonSize = height * 0.55;
    if (buttonSize < 30) buttonSize = 30;
    return buttonSize;
  }

  const handleResize = useCallback(() => {
    imgJaugeRef?.current &&
      setBigButtonHeight(getButtonHeight(imgJaugeRef.current));
  }, [setBigButtonHeight]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  useLayoutEffect(() => {
    if (imgJaugeRef.current) {
      imgJaugeRef.current.onload = handleResize;
      handleResize();
    }

  }, [handleResize]);

  const classes = useStyles(
    isButtonPressed,
    isButtonActive,
    angle,
    bigButtonHeight
  )();

  const onButtonPress = useCallback(async () => {
    if (isButtonPressed || !isButtonClickable) return;
    setIsButtonPressed(true);
    setIsButtonClickable(false);
    await wait(500);
    setIsButtonPressed(false);
    await wait(300);
    onButtonClick();
    setIsButtonClickable(true);
  }, [onButtonClick, setIsButtonPressed, isButtonPressed, isButtonClickable]);


  return (
    <Box
      height="100%"
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <div className={classes.jauge}>
        <img
          ref={imgJaugeRef}
          className={classes.img}
          src={jaugeImg}
          height="100%"
          alt="joy o'meter"
        />
        <div className={classes.bigButton} onMouseDown={onButtonPress}>
          <img className={classes.needle} src={needle} alt="needle" />
          <img
            className={classes.btnBackground}
            src={btnBackground}
            alt="buttonBack"
          />
          <img className={classes.btn} src={btnImg} alt="buttonImg" />
        </div>
      </div>
    </Box>
  );
};

export default Jauge;
