import { createMuiTheme } from "@material-ui/core/styles";
import orange from "@material-ui/core/colors/orange";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 700,
      md: 900,
      lg: 1280,
      xl: 1920,
    },
  },
  typography: {
    fontFamily: "Roboto",
    body1: {
      fontSize: "1.2rem",
      fontWeight: 300,
      lineHeight: "1.3",
      "@media (max-width:800px)": {
        fontSize: "1rem",
      },
    },
    body2: {
      fontSize: "1.1rem",
      fontWeight: 800,
      "@media (max-width:800px)": {
        fontSize: "0.75rem",
        lineHeight: "1.2",
      },
    },
    h1: {
      fontSize: "10rem",
      fontWeight: 900,
      lineHeight: 0.8,
    },
  },
  palette: {
    primary: {
      main: orange[300],
    },
    secondary: {
      main: orange[500],
    },
  },
});

export default theme;
