import React from "react";
import { makeStyles, Typography, Button, Fab } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { GoodNewType } from "../Main";

const useStyles = (isOpen: boolean) =>
  makeStyles((theme) => ({
    root: {
      width: "100%",
      height: "100%",
    },
    header: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(4),
      display: "flex",
      justifyContent: "flex-end",
      "@media (orientation:landscape) and (max-width:800px)": {
        paddingLeft: 0,
        paddingRight: theme.spacing(6),
        paddingTop: theme.spacing(2),
        marginBottom: theme.spacing(-2),
      },
    },
    bigTitle: {
      background: "#fff",
      color: "#101439",
      overflow: "hidden",
      "& div": {
        paddingLeft: theme.spacing(4),
        fontSize: "15vmin",
        fontWeight: 900,
        lineHeight: 0.8,
        display: "block",
        transform: "translateY(2vmin)",
      },
    },
    bigTitleText: {
      transform: "translateY(2vmin)",
    },
    subTitle: {
      color: theme.palette.primary.main,
      textTransform: "uppercase",
      fontWeight: 800,
      paddingLeft: theme.spacing(4),
      paddingBottom: theme.spacing(1),
      fontSize: "2.5vh",
      "@media (orientation: portrait)": {
        fontSize: "2vh",
      },
    },
    secondaryTitle: {
      color: theme.palette.primary.main,
      fontSize: "5vh",
      fontWeight: 200,
      maxWidth: "80%",
      padding: `4vh ${theme.spacing(4)}px`,
      "@media (max-width:1100px)": {
        maxWidth: "100%",
      },
      "@media (orientation: portrait)": {
        maxWidth: "100%",
        fontSize: "3vh",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        lineHeight: "1.1",
      },
    },
    closeButton: {
      width: "10vmin",
      height: "10vmin",
      minHeight: "10vmin",
      "& svg": {
        width: "8vmin",
        height: "8vmin",
      },
    },
    text: {
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
      maxWidth: "80%",
      color: "#fff",
      "& > p": {
        fontSize: "3vh!important",
      },
      "@media (orientation: portrait)": {
        maxWidth: "100%",
        "& > p": {
          fontSize: "2vh!important",
        },
      },
      "@media (max-width:1100px)": {
        maxWidth: "100%",
      },
      "@media (orientation:landscape) and (max-width:800px)": {
        "& > p": {
          fontSize: "3.5vh!important",
        },
      },
    },
    sourceButton: {
      borderRadius: 0,
      marginTop: theme.spacing(4),
      fontWeight: 800,
      fontSize: "2vmin",
      "@media (orientation:landscape) and (max-width:800px)": {
        marginTop: theme.spacing(2),
      },
    },
  }));

const NewsModal: React.ComponentType<{
  isOpen: boolean;
  content?: GoodNewType;
  onClose: (e: any) => void;
  number: number;
}> = ({ isOpen, content, onClose, number }) => {
  const classes = useStyles(isOpen)();

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Fab
          color="primary"
          className={classes.closeButton}
          size="large"
          aria-label="delete"
          onClick={onClose}
        >
          <Close />
        </Fab>
      </div>
      <div className={classes.subTitle}>{`Bonne nouvelle #${number}`}</div>
      <div className={classes.bigTitle}>
        <div className={classes.bigTitleText}>{content?.category}</div>
      </div>
      <div className={classes.secondaryTitle}>{content?.title}</div>
      <div className={classes.text}>
        <Typography variant="body1" color="inherit">
          {content?.description}
        </Typography>
        {content?.source && (
          <Button
            disableElevation
            className={classes.sourceButton}
            variant="contained"
            color="primary"
            href={content?.source}
            size='small'
            target="_blank"
            rel="noopener noreferer"
          >
            Voir la source
          </Button>
        )}
      </div>
    </div>
  );
};

export default NewsModal;
