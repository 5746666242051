import React, { useCallback, useState, useRef } from "react";
import { makeStyles, Box } from "@material-ui/core";
import bgImage from "./assets/background.jpg";
import logoImg from "./assets/logo.svg";
import madewithlove from "./assets/madewithlove.svg";
import madewithloveMobile from "./assets/madewithlove_mobile.svg";
import Jauge from "./components/Jauge";
import NewsModal from "./components/NewsModal";
import EndModal from "./components/EndModal";
import gsap, { Power4 } from "gsap";
import goodNewsList from "./data/news.json";

const NEWS_TYPES = {
  COOP: "Coopératives",
  TILLEULS: "Les Tilleuls",
  INSOLITE: "Insolite",
  ANIMAUX: "Animaux",
  BIODIVERSITE: "Biodiversité",
  SOCIETE: "Société",
  HEROS: "Héros",
  SANTE: "Santé"
};

const useStyles = (isModalOpen: boolean) =>
  makeStyles((theme) => ({
    background: {
      width: "100%",
      height: "100%",
      backgroundImage: `url(${bgImage})`,
      backgroundSize: "cover",
      backgroundPosition: "center center",
      display: "grid",
      gridTemplateAreas: '"logo text" "content content"',
      gridTemplateRows: "auto minmax(0, 1fr)",
      gridTemplateColumns: "300px 1fr",
      padding: theme.spacing(4),
      alignItems: "center",
      justifyContent: "center",
      position: "relative",
      overflow: "hidden",
      "@media (orientation: portrait)": {
        gridTemplateAreas: '"logo" "text" "content"',
        gridTemplateRows: "auto auto 1fr",
        gridTemplateColumns: "1fr",
      },
      "@media (orientation:landscape) and (max-width:1100px)": {
        gridTemplateColumns: "180px 1fr",
      },
    },
    logo: {
      maxWidth: "100%",
      gridArea: "logo",
      padding: theme.spacing(4),
      "@media (orientation: portrait)": {
        maxHeight: "90px",
        padding: theme.spacing(2),
      },
      "@media (orientation:landscape) and (max-width:1100px)": {
        padding: theme.spacing(2),
      },
    },
    text: {
      gridArea: "text",
      borderLeft: `3px solid ${theme.palette.primary.main}`,
      paddingRight: theme.spacing(16),
      paddingLeft: theme.spacing(4),
      color: "#fff",
      fontSize: "3vh",
      fontWeight: 300,
      lineHeight: "1.3",
      "@media (max-width:1200px)": {
        paddingRight: theme.spacing(10),
      },
      "@media (max-width:800px)": {
        paddingRight: theme.spacing(4),
      },
      "@media (orientation: portrait)": {
        borderLeft: "none",
        borderTop: `3px solid ${theme.palette.primary.main}`,
        paddingTop: theme.spacing(2),
        paddingLeft: 0,
        paddingRight: 0,
        fontSize: "2vh"
      },
      "@media (orientation:landscape) and (max-width:800px)": {
        fontSize: "0.8rem",
      },
    },
    content: {
      gridArea: "content",
      height: "100%",
    },
    modal: {
      top: 0,
      left: 0,
      position: "fixed",
      width: "100%",
      height: "100%",
      opacity: 0,
      pointerEvents: "none",
    },
    ribbon: {
      position: "fixed",
      top: "70px",
      right: "-55px",
      width: "280px",
      transform: "rotate(45deg)",
      "@media (max-width:1200px)": {
        display: "none",
      },
    },
    ribbonMobile: {
      position: "fixed",
      bottom: "10px",
      left: "50%",
      width: "200px",
      transform: "translateX(-50%)",
      "@media (min-width:1201px)": {
        display: "none",
      },
      "@media (max-width:800px)": {
        width: "150px",
      },
    },
  }));

export interface GoodNewType {
  title: string;
  description?: string;
  source?: string;
  category: string;
}

const shuffleArray = (base: GoodNewType[]) => {
  const array: GoodNewType[] = [...base];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const findNewByCategory = (arr: GoodNewType[], categories: string[]) => {
  return arr.find((news) => categories.includes(news.category));
};

const getAvailableNews = () => {
  const allNews: GoodNewType[] = shuffleArray(goodNewsList);
  const selectedNews: GoodNewType[] = [
    findNewByCategory(allNews, [NEWS_TYPES.COOP, NEWS_TYPES.TILLEULS]),
    findNewByCategory(allNews, [NEWS_TYPES.BIODIVERSITE, NEWS_TYPES.ANIMAUX]),
    findNewByCategory(allNews, [NEWS_TYPES.HEROS]),
    findNewByCategory(allNews, [NEWS_TYPES.INSOLITE]),
    findNewByCategory(allNews, [NEWS_TYPES.SOCIETE, NEWS_TYPES.SANTE]),
  ] as GoodNewType[];
  return shuffleArray(selectedNews);
};

const Main: React.ComponentType = () => {
  const [availableNews, setAvailableNews] = useState<GoodNewType[]>(
    getAvailableNews()
  );

  const main = useRef<HTMLDivElement>(null);
  const modal = useRef<HTMLDivElement>(null);
  const jauge = useRef<HTMLDivElement>(null);
  const logo = useRef<HTMLImageElement>(null);
  const text = useRef<HTMLDivElement>(null);
  const ribbon = useRef<HTMLImageElement>(null);
  const mobileRibbon = useRef<HTMLImageElement>(null);

  const [jaugeLevel, setJaugeLevel] = useState(0);
  const [goodNew, setGoodNew] = useState<GoodNewType | undefined>(undefined);
  const [step, setStep] = useState("jauge");
  const [isAnimated, setIsAnimated] = useState<boolean>(false);

  const classes = useStyles(step === "news")();

  const resetNews = useCallback(() => {
    setAvailableNews(getAvailableNews());
  }, [setAvailableNews]);

  const wait = (ms: number) =>
    new Promise((resolve) => setTimeout(resolve, ms));

  const handleScreenLeave = useCallback(() => {
    setIsAnimated(true);
    gsap.to(jauge.current, {
      duration: 1,
      autoAlpha: 0,
      y: "50%",
      ease: Power4.easeOut,
    });
    gsap.to(logo.current, {
      duration: 1,
      autoAlpha: 0,
      x: "-100%",
      ease: Power4.easeOut,
    });
    gsap.to(ribbon.current, {
      duration: 0.5,
      autoAlpha: 0,
      ease: Power4.easeOut,
      x: "+50%",
    });
    gsap.to(mobileRibbon.current, {
      duration: 0.5,
      autoAlpha: 0,
      ease: Power4.easeOut,
    });
    gsap.to(text.current, {
      duration: 1,
      autoAlpha: 0,
      x: "100%",
      ease: Power4.easeOut,
      onComplete: () => setIsAnimated(false)
    });
  }, [jauge, logo, ribbon, text]);

  const handleScreenEnter = useCallback(
    (onComplete) => {
      setIsAnimated(true);
      gsap.to(logo.current, {
        delay: 0.3,
        duration: 0.5,
        autoAlpha: 1,
        x: 0,
        ease: Power4.easeOut,
      });
      gsap.to(ribbon.current, {
        delay: 0.8,
        duration: 1,
        autoAlpha: 1,
        x: "+0%",
        ease: Power4.easeOut,
      });
      gsap.to(mobileRibbon.current, {
        delay: 0.3,
        duration: 0.5,
        autoAlpha: 1,
        ease: Power4.easeOut,
      });
      gsap.to(text.current, {
        delay: 0.3,
        duration: 0.5,
        autoAlpha: 1,
        x: 0,
        ease: Power4.easeOut,
      });
      gsap.set(jauge.current, {
        autoAlpha: 0,
        scale: 0.5,
        ease: Power4.easeOut,
      });
      gsap.to(jauge.current, {
        duration: 1,
        delay: 0.5,
        scale: 1,
        y: "0%",
        autoAlpha: 1,
        ease: Power4.easeOut,
        onComplete,
      });
    },
    [jauge, logo, ribbon, text]
  );

  const increaseJaugeLevel = useCallback(async () => {
    setJaugeLevel(jaugeLevel + 1);
    if (jaugeLevel === 4) {
      await wait(1000);
      handleScreenLeave();
      setStep("end");
      return;
    }
    await wait(500);
    setStep("jauge");
    setIsAnimated(false);
  }, [setJaugeLevel, jaugeLevel, setStep, handleScreenLeave]);

  const onBigButtonClick = useCallback(() => {
    if (isAnimated) return;
    if (step === "jauge") {
      const isLastNew = availableNews.length <= 1;
      availableNews.length && setGoodNew(availableNews.pop());
      if (isLastNew) resetNews();
      handleScreenLeave();
      gsap.set(modal.current, {
        scale: 1.3,
        transformOrigin: "50% 50%",
        autoAlpha: 0,
        delay: 0.5,
      });
      gsap.to(modal.current, {
        pointerEvents: "initial",
        delay: 0.5,
        scale: 1,
        autoAlpha: 1,
        ease: Power4.easeOut,
        duration: 1,
      });
      setStep("news");
    }
  }, [handleScreenLeave, availableNews, step, setStep, setGoodNew, resetNews, isAnimated]);

  const onCloseModal = useCallback(async () => {
    gsap.to(modal.current, {
      scale: 1.5,
      autoAlpha: 0,
      ease: Power4.easeOut,
      duration: 1,
    });
    handleScreenEnter(increaseJaugeLevel);
  }, [increaseJaugeLevel, handleScreenEnter]);

  return (
    <>
      <div className={classes.background} ref={main}>
        <img
          ref={logo}
          src={logoImg}
          className={classes.logo}
          alt="Good News Generator"
        />
        <div className={classes.text} ref={text}>
          On ne va pas se mentir : l’année qui vient de s’écouler a été des plus
          moroses... Pour vous aider à démarrer du bon pied cette année 2021,{" "}
          <strong>
            on a sélectionné rien que pour vous des anecdotes et des bonnes
            nouvelles qui devraient vous redonner le sourire&nbsp;!
          </strong>
        </div>
        <a href="http://www.les-tilleuls.coop" target="_blank" rel="noreferrer">
          <img
            ref={ribbon}
            className={classes.ribbon}
            src={madewithlove}
            alt="Made with love by Les-Tilleuls.coop"
          />
        </a>
        <div className={classes.content} ref={jauge}>
          <Jauge
            level={jaugeLevel}
            onButtonClick={onBigButtonClick}
            isButtonActive={step === "jauge"}
          />
        </div>
      </div>
      {step !== "end" && (
        <div className={classes.modal} ref={modal}>
          <NewsModal
            content={goodNew}
            number={jaugeLevel + 1}
            isOpen={step === "news"}
            onClose={onCloseModal}
          />
        </div>
      )}
      {step === "end" && (
        <Box width="100%" height="100%" position="fixed" top="0" left="0">
          <EndModal />
        </Box>
      )}
      <a href="http://www.les-tilleuls.coop" target="_blank" rel="noreferrer">
        <img
          className={classes.ribbonMobile}
          ref={mobileRibbon}
          src={madewithloveMobile}
          alt="Made with love by Les-Tilleuls.coop"
        />
      </a>
    </>
  );
};

export default Main;
