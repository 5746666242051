import React, { useRef, useEffect, useState } from "react";
import { makeStyles, Box } from "@material-ui/core";
import year from "../assets/2021.svg";
import topYear from "../assets/2021-top.svg";
import bottomYear from "../assets/2021-bottom.svg";
import facebookIcon from "../assets/facebook.svg";
import twitterIcon from "../assets/twitter.svg";
import linkedinIcon from "../assets/linkedin.svg";
import gsap, { Power4 } from "gsap";
import orange from "@material-ui/core/colors/orange";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";

const useStyles = (isPanelOpen: boolean) =>
  makeStyles((theme) => ({
    happyNewYear: {
      display: "flex",
      textAlign: "center",
      color: "#fff",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      height: "90%",
      transition: "all ease 0.5s 1s",
      transform: isPanelOpen ? "scale(0.7) translateX(-150px)" : "none",
      "@media (orientation: portrait)": {
        transform: isPanelOpen
          ? "scale(0.7) translateY(-75px)"
          : "translateY(5%)",
      },
    },
    text: {
      fontSize: "2rem",
      fontWeight: 200,
      maxWidth: "60%",
      padding: theme.spacing(2),
      margin: "0 auto",
      "@media (max-width: 1100px), (orientation: portrait)": {
        maxWidth: "100%",
        fontSize: "1.3rem",
      },
    },
    big: {
      fontSize: "2.3rem",
      padding: theme.spacing(4),
      "@media (max-width: 1100px), (orientation: portrait)": {
        maxWidth: "100%",
        fontSize: "1.6rem",
        padding: theme.spacing(2),
      },
      "@media (max-width: 1100px)": {
        maxWidth: "80%",
      },
    },
    year: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      flex: 1,
      position: "relative",
      width: "100%",
      "@media (max-width: 1100px), (orientation: portrait)": {
        maxWidth: "90%",
      },
    },
    yearImg: {
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
    },
    share: {
      position: "fixed",
      width: "30%",
      height: "100%",
      background: orange[700],
      left: isPanelOpen ? "70%" : "100%",
      top: 0,
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      fontSize: "1.8rem",
      fontWeight: 500,
      color: "#fff",
      boxShadow: theme.shadows[24],
      transition: "all ease 0.5s 1s",
      "@media (max-width: 1100px)": {
        fontSize: "1.2rem",
      },
      "@media (orientation: portrait)": {
        height: "150px",
        width: "100%",
        top: isPanelOpen ? "calc(100% - 150px)" : "100%",
        left: 0,
      },
    },
    shareButton: {
      width: "70px",
      padding: theme.spacing(1),
      borderRadius: "50%",
      transition: "all ease 0.3s",
      "&:hover": {
        background: orange[900],
        transform: "scale(0.9)",
      },
      "@media (max-width: 1100px)": {
        width: "60px",
      },
      "@media (max-width: 800px)": {
        width: "40px",
      },
    },
  }));

const URL = "https://goodnews.les-tilleuls.coop/";

const EndModal: React.ComponentType = () => {
  const text1 = useRef<HTMLDivElement>(null);
  const text2 = useRef<HTMLDivElement>(null);
  const topYearImg = useRef<HTMLImageElement>(null);
  const bottomYearImg = useRef<HTMLImageElement>(null);
  const yearImg = useRef<HTMLImageElement>(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const classes = useStyles(isPanelOpen)();

  useEffect(() => {
    gsap.set(text1.current, { opacity: 0, y: '-50%' });
    gsap.set(text2.current, { opacity: 0, y: '-20%' });
    gsap.set(yearImg.current, { opacity: 0, scale: 0.5 });
    gsap.set(topYearImg.current, { opacity: 0, top: "50%" });
    gsap.set(bottomYearImg.current, { opacity: 0, top: "-50%" });
    const tl = gsap.timeline({
      delay: 0.2,
      ease: Power4.easeOut,
      onComplete: () => setIsPanelOpen(true),
    });
    tl.to(text1.current, { opacity: 1, y:0, duration: 0.5 }, "text");
    tl.to(text2.current, {
      opacity: 1,
      y: 0,
      duration: 0.5,
      delay: 0.4,
    });
    tl.to(topYearImg.current, { opacity: 1, top: 0, duration: 0.8 }, "year");
    tl.to(bottomYearImg.current, { opacity: 1, top: 0, duration: 0.8, delay: 0.2}, "year");
    tl.to(yearImg.current, { opacity: 1, scale: 1, duration: 1, delay: 0.4 }, "year");
  }, []);

  return (
    <>
      <div className={classes.happyNewYear}>
        <div ref={text1} className={`${classes.text} ${classes.big}`}>
          Avec ces nouvelles, vous voilà prêt·e·s à démarrer l'année avec le
          sourire&nbsp;!
        </div>
        <div ref={text2} className={classes.text}>
          Les-Tilleuls.coop vous souhaite
        </div>
        <div className={classes.year}>
          <img
            className={classes.yearImg}
            ref={topYearImg}
            src={topYear}
            alt="Une excellente"
          />
          <img
            src={year}
            ref={yearImg}
            className={classes.yearImg}
            alt="2021"
          />
          <img
            className={classes.yearImg}
            ref={bottomYearImg}
            src={bottomYear}
            alt="année"
          />
        </div>
      </div>
      <div className={classes.share}>
        <span>Vous aussi, partagez ces anecdotes autour de vous !</span>
        <br />
        <Box display="flex" flexDirection="row" alignItems="center">
          <FacebookShareButton
            url={URL}
            quote="Découvrez Good News Generator, le générateur de bonnes nouvelles de Les-Tilleuls.coop et démarrez 2021 avec le sourire !"
          >
            <img
              className={classes.shareButton}
              src={facebookIcon}
              alt="Partager sur Facebook"
            />
          </FacebookShareButton>
          <TwitterShareButton
            url={URL}
            title="Découvrez Good News Generator, le générateur de bonnes nouvelles de @coopTilleuls et démarrez 2021 avec le sourire !"
          >
            <img
              className={classes.shareButton}
              src={twitterIcon}
              alt="Partager sur Twitter"
            />
          </TwitterShareButton>
          <LinkedinShareButton
            url={URL}
            title="Good News Generator"
            summary="Découvrez Good News Generator, le générateur de bonnes nouvelles de Les-Tilleuls.coop et démarrez 2021 avec le sourire !"
          >
            <img
              className={classes.shareButton}
              src={linkedinIcon}
              alt="Partager sur Linkedin"
            />
          </LinkedinShareButton>
        </Box>
      </div>
    </>
  );
};

export default EndModal;
